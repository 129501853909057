/* eslint-disable import/no-anonymous-default-export */
/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import Cookie, { getQueryParams } from "./helpers.js";
import {subscribeOnStream, unsubscribeFromStream} from "./streaming.js"

let data = [];

let lastBarsCache = new Map();

// TODO: Move these functions to utils and make use of those in other files as well. (streaming.js)

function checkEnvironment() {
  const origin = window.location.origin;

  if (origin === 'https://charts.wealthy.in') {
    return true;
  } else {
    return false;
  }
}

// const url = "https://charts.wealthydev.in/redirect?auth='xyz'&en='NSE'&token='3045'&ts='SBIN-EQ'";

const isProd = checkEnvironment();

const baseURL = isProd ? "https://api.wealthy.in/broking" : "https://api.wealthydev.in/broking";
const searchBaseURL = isProd ? "https://api.wealthy.in/scout" : "https://api.wealthydev.in/scout";
const hasAuthCookie = Cookie.getCookie("wl_authorization");
const prodToken = hasAuthCookie ? hasAuthCookie : "7dfaf86c-5bf1-45a4-8777-2103a1dfca61:KhDWgG2Kg2t1hb1m4VfySMjBa";
const devToken = hasAuthCookie ? hasAuthCookie : "30ed49c9-a0de-4d16-860e-5591f86d4e53:ANvKLqnKH76IMclEWStPInkUa";

const exchangeNameMap = {
  1: "NSE",
  2: "NFO",
  3: "BSE",
  4: "BFO"
};

const fullURL = window.location.href;
const queryParams = getQueryParams(fullURL);

const configurationData = {
  supported_resolutions: ["1", "3", "5", "30", "15", "1H", "4H", "1D", "1W", "1M"],
  exchanges: [
    {
      value: 'All',
      name: 'All',
      desc: 'All',
    },
    {
      value: 'NSE',
      name: 'NSE',
      desc: 'NSE',
    },
    {
      value: "BSE",
      name: "BSE",
      desc: "BSE"
    }
  ]
};

export default {
	onReady: (callback) => {
		setTimeout(() => callback(configurationData));
	},

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback,
) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", isProd ? prodToken : devToken);
      myHeaders.append("X-APP-VERSION", "broking-web");

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const res = await fetch(`${searchBaseURL}/v0/search/?q=${userInput}&pt=stocks`, requestOptions)
      if (res.status === 200) {
        data = await res.json();

        let sampleSymbol = {};
          const newSymbols = [];
          const stocks = data?.stocks || [];
          const fno = data?.fno || [];
          data = [
            ...stocks,
            ...fno
          ]

          data.map((eachData) => {
            const name = eachData.trading_symbol || eachData.name
            const instrumentType = eachData.instrument_type
            const sampleTicker = name + ":" + eachData.token + ":" + exchangeNameMap[eachData.exchange_name] + ":" + instrumentType
            sampleSymbol = {}

            sampleSymbol.symbol = name,
            sampleSymbol.full_name = sampleTicker,
            sampleSymbol.token = eachData.token,
            sampleSymbol.description = name,
            sampleSymbol.exchange = exchangeNameMap[eachData.exchange_name],
            sampleSymbol.type = instrumentType,
            newSymbols.push(sampleSymbol);
          });
          onResultReadyCallback(newSymbols);
      } else {
        onResultReadyCallback([]);
      }
    } catch (error) {
      console.error(error)
    }
},

	resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    try {
      const splitValue = symbolName.split(":");

      if (symbolName && symbolName.length > 0) {
        const symbolInfo = {
          ticker: queryParams?.token,
          name: queryParams?.ts,
          description: queryParams?.ts,
          type: queryParams?.insType ?? "Stock",
          session: '2;0915-1530:23456',
          exchange: queryParams?.en || "NSE",
          timezone: 'Asia/Kolkata',
          minmov: 1,
          pricescale: 100,
          supported_resolution: ['1', '3', '5', '30', '15', '1H', '4H', '1D', '1W', '1M'],
          has_intraday: true,
          visible_plots_set: queryParams?.insType === "INDEX" ? "ohlc" : 'ohlcv',
          has_weekly_and_monthly: true,
          volume_precision: 2,
          data_status: 'streaming'
        };
        onSymbolResolvedCallback(symbolInfo);
      } else {
      }
    } catch (error) {
      onResolveErrorCallback(error);
    }
  },

	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    let {from, to, firstDataRequest} = periodParams;

    const resolutionResolver = {
      "1M": 1113,
      "1W": 1112,
      "1D": 1111,
      "30": 30,
      "60": 60,
      "240": 240,
      "1": 1,
      "15": 15,
      "3": 3,
      "5": 5
    };
    const selectedResolution = resolutionResolver[resolution];

    const exchangeNameReverseMap = {
      "NSE": 1,
      "NFO": 2,
      "BSE": 3,
      "BFO": 4
    };

      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", isProd ? prodToken : devToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-APP-VERSION", "broking-web");

        const {
          ticker,
          name,
          exchange,
          type
        } = symbolInfo;

        const raw = JSON.stringify({
          "exchange_name": exchangeNameReverseMap[exchange] ?? 1,
          "token": ticker ?? "3045",
          "trading_symbol": name ?? "SBIN-EQ",
          "start_time" : from,
          "end_time" : to,
          "interval": selectedResolution,
          "params": type === "INDEX" ? "o,h,l,c" : "o,h,l,c,v"
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw
        };

        const res = await fetch(`${baseURL}/api/v0/stock/chart-data/`, requestOptions);

        if (res.status === 200 && res?.data !== null) {
          data = await res.json();
          let bars = [];

          if (data && data.length) {

            data.forEach(bar => {
              // let barTime = new Date(bar.interval.d).getTime() / 1000;
              // let timeForBar = new Date(bar.interval.d).getTime();


              const barTime = new Date(bar.interval.d);

              // Convert the date to UTC
              // barTime.setMinutes(barTime.getMinutes() - barTime.getTimezoneOffset());

              // Convert to epoch time
              // const timeForBar = barTime.getTime();

              // 19800000 -> 5hrs + 30 mins (for converting to UTC time zone)


              if (resolution === "1D" || resolution === "1W" || resolution === "1M") {
                // timeForBar = timeForBar + 19800000;
                barTime.setMinutes(barTime.getMinutes() - barTime.getTimezoneOffset());
              }


              // Convert to epoch time
              const timeForBar = barTime.getTime();



              // if (barTime >= from && barTime <= to) {
                bars = [...bars, {
                  time: timeForBar,
                  low: parseFloat(bar.interval.l),
                  high: parseFloat(bar.interval.h),
                  open: parseFloat(bar.interval.o),
                  close: parseFloat(bar.interval.c),
                  volume: parseFloat(bar.interval.v)
                }];
              // }
            });

            if (firstDataRequest) {
              lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
            }
              onHistoryCallback(bars, {noData: false});
            } else {
              onHistoryCallback([], {noData: true});
            }
        } else {
          onHistoryCallback([], {noData: true});
        }
      } catch (error) {
        onErrorCallback(error);
      }
  },

  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
    const {
      ticker,
      exchange
    } = symbolInfo;

    subscriberUID = `${exchange}|${ticker}`;

    subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback, lastBarsCache.get(symbolInfo.full_name));
  },

  unsubscribeBars: (subscriberUID) => {
    console.log("this is executed first", subscriberUID);
    unsubscribeFromStream(subscriberUID);
  },
}