/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

// // Makes requests to get search results
// export async function makeApiRequest(path) {
//   try {
//       const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
//       return response.json();
//   } catch(error) {
//       throw new Error(`CryptoCompare request error: ${error.status}`);
//   }
// }

// // Generates a symbol ID from a pair of the coins
// export function generateSymbol(exchange, fromSymbol, toSymbol) {
//   const short = `${fromSymbol}/${toSymbol}`;
//   return {
//       short,
//       full: `${exchange}:${short}`,
//   };
// }


// Makes requests to CryptoCompare API
export async function makeApiRequest(path) {
  try {
      const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
      return response.json();
  } catch(error) {
      throw new Error(`CryptoCompare request error: ${error.status}`);
  }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
      short,
      full: `${exchange}:${short}`,
  };
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
      return null;
  }
  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}

export function getQueryParams(url) {
  const queryParams = {};
  const urlSearchParams = new URLSearchParams(url.slice(url.indexOf('?') + 1));

  for (const [key, value] of urlSearchParams.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
};

export function setQueryParams(url, data) {
  const urlSearchParams = new URLSearchParams(url.slice(url.indexOf('?') + 1));

  console.log(urlSearchParams)
}

export const removeAuthFromHref = (oldHref)=>{
  const baseUrl = oldHref.split("?")[0];
  const filteredQueryArr = oldHref.split("?")[1].split("&").filter((param=>param.split("=")[0]!=="auth"));
  let filteredQueryParams = "";
  filteredQueryArr.map((ele,i)=>{
    if(i!=filteredQueryArr.length-1)ele+="&"
    filteredQueryParams+=ele;
  });
  return baseUrl+"?"+filteredQueryParams;
}

class Cookie{
  static setCookie = (key,value)=>{
    document.cookie = `${key}=${value}`;
  }

  static getCookie = (key)=>{
    return document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${key}=`))
    ?.split("=")[1];
  }
}
export default Cookie;

